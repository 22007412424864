@import '../node_modules/font-awesome/css/font-awesome.css';
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

#menu-nav {
  border-top-left-radius: 5px;
  font-size: 0;
  padding: 0;
  margin-left: 14px
}
@media (max-width:64em) {
  #menu-nav {
	margin-left: 0
  }
}
#menu-nav ul li {
  background: #f5f5f5;
  display: inline;
  text-align: center;
  border: 1px solid rgba(40,44,42,.1);
  background: rgba(40,44,42,.05)
}
#menu-nav ul li:not(:last-child) {
  border-right: none
}
#menu-nav a {
  display: inline-block;
  margin: 0;
  text-decoration: none;
  color: #74777b
}
#menu-nav .a {
  color: #23dbdb;
  text-decoration: none
}
#menu-nav a:hover {
  color: #000
}
#menu-nav ol,
#menu-nav ul {
  list-style: none;
  padding: 0;
  margin: 0
}
#menu-nav .inner {
  margin: 0 auto
}
#menu-nav #wrap > header {
  padding-bottom: 20px
}
#menu-nav #main-menu-toggle {
  display: none
}
#menu-nav #main-menu > li {
  display: inline-block;
  padding: 0;
  background-color: #ddd
}
#menu-nav #main-menu > li > a {
  font-size: 14px;
  line-height: 14px;
  text-decoration: none;
  padding: 10px 5px 20px 5px;
  border: 0 none;
  font-weight: 400;
  text-transform: uppercase;
  padding: 10px 0;
  margin: 0 10px
}
#menu-nav #main-menu > li > a > i {
  font-size: 20px
}
#menu-nav #main-menu > li > a > i.fa-chevron-down {
  font-weight: 100;
  font-size: 8px
}
#menu-nav #main-menu > li.active > a,
#menu-nav #main-menu > li:hover > a,
#menu-nav .sub-menu > li.active > a {
  color: #337ab7
}
#menu-nav #main-menu > li.active {
  border-top: 1px solid #337ab7;
  box-shadow: inset 0 2px 0 #337ab7;
  -webkit-box-shadow: inset 0 2px 0 #337ab7;
  transition: .3s ease-in-out;
  border-bottom: none;
  background-color: #f5f5f5;
  padding-bottom: 1px
}
#menu-nav ul.sub-menu {
  display: none;
  padding-top: 3px;
  box-shadow: inset 0 3px 0 #337ab7;
  -webkit-box-shadow: inset 0 3px 0 #337ab7;
  position: absolute;
  width: 200px;
  z-index: 9999;
  color: #74777b
}
#menu-nav ul.sub-menu > li > a {
  background-color: #ddd;
  border: 1px solid #444;
  border-top: none;
  display: block;
  font-size: 13px;
  line-height: 15px;
  text-decoration: none;
  line-height: 2.5
}
#menu-nav ul.sub-menu > li > a:hover {
  background-color: #2a2a2a;
  color: #fff
}
#menu-nav ul.sub-menu > li:first-child {
  border-top: 3px solid #337ab7
}
#menu-nav ul.sub-menu ul.sub-menu > li:first-child {
  border-top: 1px solid #444
}
#menu-nav ul.sub-menu > li:last-child > a {
  border-radius: 0 0 2px 2px
}
#menu-nav ul.sub-menu > li > a.parent {
  background-size: 5px 9px;
  background-repeat: no-repeat;
  background-position: 95% center
}
#menu-nav #main-menu li:hover > ul.sub-menu {
  display: block
}
@media all and (max-width:64em) {
  #menu-nav ul li {
	text-align: left;
	border-right: none
  }
  #menu-nav #navigation {
	margin-top: 20px
  }
  #menu-nav #main-menu-toggle {
	display: block
  }
  #menu-nav #main-menu {
	/*display: none;*/
	float: none
  }
  #menu-nav #main-menu li {
	display: block;
	margin: 0;
	padding: 0
  }
  #menu-nav #main-menu > li {
	margin-top: -1px;
	background-color: #565656;
	border-bottom: 1px solid #ddd
  }
  #menu-nav #main-menu > li:first-child {
	margin-top: 0
  }
  #menu-nav #main-menu > li > a {
	color: #bbb;
	display: block;
	font-size: 14px;
	padding: 12px!important;
	padding: 0
  }
  #menu-nav #main-menu > li.parent {
	padding: 0
  }
  #menu-nav #main-menu > li.active {
	border: 2px solid #337ab7!important;
	box-shadow: inset 0 0 4px #337ab7;
	-webkit-box-shadow: inset 0 0 4px #337ab7
  }
  #menu-nav ul.sub-menu {
	display: block;
	margin-top: -1px;
	margin-left: 22px;
	position: static;
	padding: 0;
	width: 90%;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none
  }
  #menu-nav ul.sub-menu > li > a {
	padding-left: 6%
  }
  #menu-nav ul.sub-menu > li:first-child {
	border-top: 1px solid #444!important
  }
  #menu-nav ul.sub-menu > li > a.parent {
	background: #333!important
  }
}
.custom-icon {
  width: 12%;
  padding-right: 15px
}
.dropdown-submenu {
  position: relative
}
.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px
}
#preloader {
  background: #2e5589 none repeat scroll 0 0
}
#sidebar-wrapper {
  background: #2e5589 none repeat scroll 0 0
}
.sidebar-nav li a.intelligence,
.sidebar-nav li a.router {
  background: #98b6e0 none repeat scroll 0 0;
  color: #2e5589;
  text-decoration: none;
  border-bottom: 1px solid #1b4e52
}
@media print {
  @page {
	size: landscape
  }
  .print-modalcontent * {
	visibility: visible
  }
  .noprint {
	visibility: hidden;
	display: none
  }
  .modal {
	position: absolute;
	left: 0;
	top: 0;
	margin: 0;
	padding: 0;
	min-height: 550px
  }
  .chart_title {
	display: block
  }
  div {
	transform-origin: 0 0;
	page-break-after: avoid;
	page-break-before: avoid;
	font-size: 11px
  }
  .page-break-inside {
	page-break-inside: avoid
  }
  .showOnPrint {
	display: block
  }
  #header,
  .ng-hide,
  .no-print {
	display: none
  }
  .chart {
	height: 200px
  }
  .panel-widget {
	height: auto;
	page-break-inside: avoid
  }
  .panel-heading {
	background-color: #f5f5f5!important;
	font-size: 20px
  }
}
* {
  outline: 0
}
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
body {
  background: #e6e9ed;
}
.ui-grid-viewport {
  overflow-anchor: none
}
#preloader {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2147483647
}
#status {
  background: rgba(0,0,0,0) url(/images/new_logo_white.png) no-repeat center center/80% auto;
  color: #fff;
  font-size: 24px;
  height: 59px;
  left: 50%;
  margin: -50px 0 0 -50px;
  position: absolute;
  text-align: center;
  top: 50%;
  vertical-align: middle;
  width: 100px;
  display: block!important
}
#status i {
  color: #fff;
  font-size: 120px;
  font-weight: 200;
  position: relative;
  top: -23px;
  left: -10px
}
.state-indicator {
  position: absolute;
  top: -999em;
  left: -999em;
  display: none;
  z-index: 1
}
@media all and (max-width:90em) {
  .state-indicator {
	z-index: 2
  }
}
@media all and (max-width:64em) {
  .state-indicator {
	z-index: 3
  }
}
@media all and (max-width:40em) {
  .state-indicator {
	z-index: 4
  }
}
.changebtn {
  position: fixed;
  z-index: 99999;
  top: 9px;
  left: 9px;
  display: block;
  padding: 5px
}
.alrtx {
  margin: 0 auto;
  position: relative;
  z-index: 9
}
.ui-grid-icon-menu::before {
  color: #fff
}
.clrpad .alrtx {
  bottom: 0;
  max-width: 700px
}
.alrtx .alert-danger,
input.ng-invalid.ng-touched.ng-validation {
  background-image: linear-gradient(to bottom,#ee6969 0,#ce4949 100%);
  background-repeat: repeat-x;
  border-color: #ce4949;
  color: #fff
}
.loginds {
  background-color: #23292f;
  border-radius: 7px;
  box-shadow: 0 1px 3px rgba(0,0,0,.3),0 1px 0 rgba(0,0,0,.027);
  margin: 100px auto 10px;
  min-width: 300px;
  overflow: hidden;
  padding: 10px 30px 30px;
  position: relative
}
@media only screen and (min-width:992px) {
  .loginds {
	width: 450px
  }
}
.backgroundlogin {
  background: rgba(250,250,250,.4) url(/images/home-image.jpg) no-repeat scroll center center/100% auto;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover
}
.copyrit {
  color: #eee;
  font-size: 12px;
  line-height: 2em;
  text-align: center
}
.pasn,
.usrn {
  position: relative
}
.pasn::before {
  color: #9c9c9c;
  content: "\f023";
  font-family: fontawesome;
  font-size: 17px;
  left: 7px;
  position: absolute;
  top: 6px
}
.usrn::before {
  color: #9c9c9c;
  content: "\f007";
  font-family: fontawesome;
  font-size: 17px;
  left: 7px;
  position: absolute;
  top: 6px
}
.bran-actview .logo-login {
  background: rgba(0,0,0,0) url(/images/new_logo_white.png) no-repeat scroll center center/106px auto;
  display: block;
  font-size: 0;
  height: 70px
}
.bran-actview span {
  color: #eee;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3px;
  line-height: 26px
}
.bran-actview .form-control {
  margin-top: 5px
}
.loginds .form-group {
  margin: 0 0 8px;
  padding: 0
}
.loginds .form-group .form-control {
  border-radius: 50px;
  font-size: 18px;
  height: 36px;
  line-height: 1.33333;
  padding: 10px 16px
}
.btnlogn {
  border-radius: 50px;
  margin-top: 20px
}
.pasn input,
.usrn input {
  padding-left: 25px!important
}
.navbar-custom .icon-bar {
  background-color: #fff
}
.bran-actview {
  height: 60px;
  margin: 13px auto 21px;
  position: relative;
  text-align: center;
  width: 300px;
  z-index: 23
}
.navbar-custom {
  border-bottom: 1px solid #eee;
  color: #fff;
  height: 90px
}
.navbar-custom li > a:focus,
.navbar-custom li > a:hover {
  background-color: #49bfff
}
.navbar-custom a {
  color: #fefefe
}
.navbar-custom .form-control:focus {
  border-color: #49bfff;
  outline: 0;
  -webkit-box-shadow: inset 0 0 0;
  box-shadow: inset 0 0 0
}
#main,
#main > .row {
  height: 100%
}
#left {
  height: 100%
}
#siteMap-canvas {
  border: 1px solid #cecece;
  border-radius: 5px;
  min-height: 420px;
  overflow: hidden;
  position: relative;
  width: 100%
}
.edixb {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  display: block;
  height: 19px;
  padding: 2px;
  vertical-align: bottom;
  width: 30px
}
.edixb:focus,
.edixb:hover {
  color: #14a4fa
}
.batt-mob-study,
.mapsx {
  padding-top: 10px;
  padding-bottom: 10px
}
.controls {
  margin-top: 10px;
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  outline: 0;
  box-shadow: 0 2px 6px rgba(0,0,0,.3)
}
#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 300px
}
#pac-input:focus {
  border-color: #4d90fe
}
.pac-container {
  font-family: Roboto
}
#type-selector {
  color: #fff;
  background-color: #4d90fe;
  padding: 5px 11px 0 11px
}
#type-selector label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300
}
#target {
  width: 345px
}
#dashboardMap-canvas {
  height: 300px;
  margin: 0;
  padding: 0
}
#mainModalMap-canvas {
  height: 320px;
  width: 630px;
  margin: 0;
  padding: 0
}
@media (min-width: 768px) {
  .modal-xl {
    width: 80%;
    max-width:1200px;
  }
}
.modal-footer {
  border-top: 0
}
.clrpad {
  padding-left: 0!important
}
.no-padding {
  padding: 0 !important;
}
.mb20 {
  margin-bottom: 20px
}
.boxs3 h2 {
  color: #457cb4;
  font-size: 18px;
  font-weight: 700;
  padding: 0 0 5px
}
.glyphicon-refresh-animate {
  -animation: spin .7s infinite linear;
  -webkit-animation: spin2 .7s infinite linear
}
@-webkit-keyframes spin2 {
  from {
	-webkit-transform: rotate(0)
  }
  to {
	-webkit-transform: rotate(360deg)
  }
}
@keyframes spin {
  from {
	transform: scale(1) rotate(0)
  }
  to {
	transform: scale(1) rotate(360deg)
  }
}
.wait-wrapper {
  top: 0;
  height: 100%;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
  float: none!important
}
#waitBox {
  height: 100%
}
#waitBoxLeft.col-xs-1 {
  width: 0!important;
  height: 100%;
  padding: 0!important
}
#waitBoxRight.col-xs-11 {
  width: 90%;
  height: 100px;
  float: none!important
}
wrapper {
  width: 100%
}
#page-wrapper {
  padding: 0
}
#content-header {
  margin-right: 0
}
#tab-content,
.tab-content {
  background: #f5f5f5;
  padding: 10px;
  margin: 0 15px;
  margin-right: 0;
  min-height: 100px
}

.tab-div{
  background: transparent;
  padding: 0px;
  margin: 0 15px;
  margin-right: 0;
  min-height: 100px

}
@media (max-width:64em) {
  #tab-content {
	margin: 0
  }
}
#tab-content.customers-report {
  margin: 0 16px
}

.boxs {
  background: #fff none repeat scroll 0 0;
  border-radius: 5px;
  border-top: 3px solid #14a4fa;
  box-shadow: 0 1px 2px rgba(0,0,0,.05);
  margin-bottom: 15px;
  padding: 4px 20px 20px
}
.table > thead > tr > th {
  border-bottom: 1px dashed #ddd;
  vertical-align: bottom
}
.table th {
  color: #969aa2;
  font-size: 13px
}
.bvf {
  border-top: 1px dashed #cecece!important;
  display: inline-block;
  margin-top: 10px;
  padding: 15px 0 0!important;
  text-align: right!important;
  width: 100%
}
.boxs3 {
  background: #fff none repeat scroll 0 0;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 4px 20px 7px;
  box-shadow: 0 1px 2px rgba(0,0,0,.05)
}
.boxs2 {
  background: #fff none repeat scroll 0 0;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 10px 10px 0 10px;
  box-shadow: 0 1px 2px rgba(0,0,0,.05)
}
.ui-widget-headerx {
  background: #457cb4;
  border: none;
  color: #fff;
  font-weight: 700
}
table .cellpadding-10 th,
td {
  padding: 10px
}
input[type=checkbox],
input[type=radio] {
  width: 18px;
  height: 18px;
  display: inline
}
.r {
  float: right!important
}
.l {
  float: left
}
.input-group-btn button {
  height: 34px
}
.pointer {
  cursor: pointer
}
.nav li {
  cursor: pointer
}
.form-buttons {
  text-align: center;
  padding-top: 40px;
  border-top: 1px solid #e5e5e5
}
.form-buttons button {
  margin-right: 20px
}
.padding-30-border-b {
  margin-bottom: 30px;
  border-bottom: 1px solid #e5e5e5
}
.red-text {
  color: red!important
}
.green-text {
  color: green
}
.inner-addon {
  position: relative
}
.inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
  pointer-events: none
}
.large-icons {
  font-size: 30px
}
.left-addon .glyphicon {
  left: 0
}
.right-addon .glyphicon {
  right: 0
}
.left-addon input {
  padding-left: 30px
}
.right-addon input {
  padding-right: 30px
}
.z-index-10k {
  z-index: 10000
}
.padding-8 {
  padding: 8px
}
.margin-right-10 {
  margin-right: 10px
}
.margin-top-4-per {
  margin-top: 4%
}
.user-actions ul li {
  cursor: pointer
}
.grid {
  width: 100%;
  height: 400px
}
.innerGrids {
  width: 650px;
  height: 400px
}
.unauthorized-box {
  font-size: 18px;
  margin-left: 35%
}
.act-logo {
  background: rgba(0,0,0,0) url(/images/new_logo_blue.png) no-repeat scroll 0 0 auto;
  display: block;
  height: 65px;
  margin: 0 auto;
  width: 99px
}
.float-none {
  float: none
}
.chart_title {
  display: none
}
.hideTags .tags .tag-item {
  display: none
}
.btnUpload {
  position: relative;
  overflow: hidden
}
.btnUpload input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  opacity: 0;
  outline: 0;
  background: #fff;
  cursor: inherit;
  display: block
}
.batteryTopNav {
  background-color: #fff;
  color: #000
}
.nav-pills > li.batteryTopNav {
  background-color: #fff;
  color: #000
}
.nav-pills > li.batteryTopNav,
.nav-pills > li.batteryTopNav.active > a,
.nav-pills > li.batteryTopNav.active > a:focus,
.nav-pills > li.batteryTopNav.active > a:hover {
  background-color: #fff;
  border-color: red;
  color: #457cb4;
  font-size: 20px
}
.boxs2 .nav li a {
  color: #9599a1;
  text-decoration: none
}
.toolss {
  float: right;
  margin-top: -60px
}
.toolss button {
  background: rgba(0,0,0,0) none repeat scroll 0 0;
  border: medium none;
  color: #868a92;
  font-size: 18px;
  margin-left: 2px
}
.toolss2 {
  float: right;
  margin-top: 14px;
  margin-right: 5px
}
.toolss2 button {
  background: rgba(0,0,0,0) none repeat scroll 0 0;
  border: medium none;
  color: #868a92;
  font-size: 18px;
  margin-left: 2px
}
.ui-grid-menu-button {
  background: #2e5589!important;
  border: 1px solid #d4d4d4;
  cursor: pointer;
  font-weight: 400;
  height: 61px!important;
  padding: 14px 0 0 4px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 2
}
table tr td {
  font-size: 12px;
  color: #858991
}
.no-border {
  border: none!important
}
table tr .ng-binding {
  color: #55729a
}
.dvBatteryUsageSummary b {
  font-size: 14px;
  font-weight: 700;
  color: #457cb4
}
.dvBatteryUsageSummary {
  color: #9599a1
}
.table input[type=text] {
  border: 1px solid #cecece;
  border-radius: 5px;
  height: 27px;
  padding: 2px 8px;
  box-shadow: 0 1px 1px rgba(0,0,0,.075) inset;
  min-width: 19%;
  margin-right: 9px
}
.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.radio input[type=radio],
.radio-inline input[type=radio] {
  margin-left: -20px;
  position: absolute;
  vertical-align: top;
  margin-top: 2px;
  margin-right: 1px
}
.ui-grid-footer-info span {
  display: block;
  margin-top: 4px;
  margin-left: 10px
}
.ui-grid-header-cell {
  -moz-user-select: none;
  background-color: #d2edff!important;
  border-color: #b2cddf!important;
  border-right: 1px solid #b2cddf
}
.ui-grid-cell-contents {
  COLOR: #2e5589;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 6px;
  vertical-align: middle
}
.toolss button:focus,
.toolss button:hover,
.toolss2 button:focus,
.toolss2 button:hover {
  color: #337ab7
}
.bb .nav-pills > li.active > a,
.bb .nav-pills > li.active > a:focus,
.bb .nav-pills > li.active > a:hover {
  background-color: #b5b9c1;
  color: #fff!important
}
.bb .nav-pills > li.active > a {
  color: #b5b9c1
}
.htl {
  color: #457cb4;
  font-size: 18px
}
.boxs2 .nav li a:focus,
.boxs2 .nav li a:hover {
  background: 0 0
}
.nav-pills > li.batteryTopNav.active {
  border-bottom: 3px solid #457cb4
}
.showOnPrint {
  display: none
}
#wrapper {
  padding-left: 0;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
  position: relative;
  min-height: 100%;
  height: auto!important;
  margin: 0 auto -40px
}
#wrapper.toggled {
  padding-left: 250px
}
#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: auto!important;
  bottom: 40px;
  top: 70px;
  margin-left: -250px;
  overflow-y: auto;
  background: #2e5589 none repeat scroll 0 0;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease
}
@media all and (max-width:64em) {
  #sidebar-wrapper {
	top: 70px;
  }
}
#wrapper.toggled #sidebar-wrapper {
  width: 250px
}
@media all and (max-width:64em) {
  #wrapper.toggled #sidebar-wrapper {
	width: 250px;
  }
}
#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px 0 15px 15px
}
#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px
}
.sidebar-nav {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  padding-top: 14px;
  clear: both;
  display: block
}
.select-wrapper {
  float: left;
  display: inline-block;
  border: 1px solid #d8d8d8;
  /*background: url(dropdown.png) no-repeat right center;*/
  cursor: pointer
}
.select-wrapper,
.select-wrapper select {
  width: 200px;
  height: 26px;
  line-height: 26px
}
.select-wrapper:hover {
  /*background: url(dropdown-hover.png) no-repeat right center;*/
  border-color: #239fdb
}
.select-wrapper .holder {
  display: block;
  margin: 0 35px 0 5px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  z-index: -1
}
.select-wrapper select {
  margin: 0;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  outline: 0;
  opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0
}
form {
  margin: 20px
}
input[type=submit] {
  float: left;
  background: #d8d8d8;
  border: 1px solid #c4c4c4;
  margin-left: 10px;
  padding: 4px 10px;
  cursor: pointer;
  outline: 0
}
input[type=submit]:hover {
  color: #fff;
  border-color: #1b7aa9;
  background-color: #239fdb
}
.sidebar-nav li {
  text-indent: 5px;
  line-height: 40px
}
.sidebar-nav li a {
  border-left: 3px solid #457cb4;
  color: #98b6e0;
  display: block;
  text-decoration: none
}
.sidebar-nav li ul {
  margin-left: 30px;
  padding: 0;
  border: none
}
.sidebar-nav li a.active,
.sidebar-nav li a:active,
.sidebar-nav li a:hover {
  background: #3364a8 none repeat scroll 0 0;
  border-left: 3px solid #98b6e0;
  color: #fff;
  text-decoration: none
}
.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none
}
.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px
}
.sidebar-nav > .sidebar-brand a {
  color: #999
}
.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: 0 0
}
@media (min-width:64.063em) {
  #wrapper2 {
	padding-left: 250px
  }
  #sidebar-wrapper {
	width: 250px
  }
  #page-content-wrapper {
	padding: 70px 0 75px 0;
	position: relative
  }
  #wrapper.toggled #page-content-wrapper {
	position: relative;
	margin-right: 0
  }
  .lfs {
	padding: 68px 0 0 0
  }
  .breadcrumb {
	border-radius: 0;
	margin-left: -25px;
	margin-right: -25px;
	padding: 23px
  }
}
.navbar-actview {
  background: #fff;
  border-radius: 0;
  border-bottom: 1px solid #e6e9ed
}
.logo-actv {
  background: rgba(0,0,0,0) url(/images/new_logo_blue.png) no-repeat scroll center top/50% auto;
  font-size: 13px;
  height: 70px;
  margin: 10px 17px 0 29px;
  padding-top: 43px;
  text-align: center;
  width: 203px
}
.navbar-brand,
.navbar-nav > li > a {
  border-bottom: 3px solid #fff;
  border-right: 1px solid #f0f2f7;
  display: block;
  font-size: 14px;
  padding: 23px 21px;
  text-shadow: 0 0 0;
  font-weight: 700;
  text-align: center
}
.navbar-brand {
  padding: 0;
  border-right: 1px solid #f0f2f7;
  display: block;
  height: 70px
}
@media (min-width:1200px) {
  .navbar-nav > li > a:active,
  .navbar-nav > li > a:focus,
  .navbar-nav > li > a:hover,
  .navbar-nav > li.active > a {
	background-color: #f0f2f7;
	border-bottom: 3px solid #14a4fa;
	padding: 23px 21px;
	text-decoration: none;
	padding-bottom: 24px
  }
}
table label {
  font-weight: 400
}
.nms span {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #fff;
  box-shadow: 0 0 0;
  color: #a6aab2;
  margin-top: 13px
}
.nms span.dropdown-toggle {
  margin-top: 0
}
.btn-group.open .dropdown-toggle,
.nms span:focus,
.nms span:hover {
  background: rgba(0,0,0,0) none repeat scroll 0 0!important;
  border: medium none!important;
  box-shadow: 0 0 0!important;
  color: #2e5589!important
}
.nms .dropdown-menu {
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px rgba(0,0,0,.22);
  top: 52px
}
.breadcrumb > .active {
  color: #b0b0b0
}
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  background-color: #337ab7;
  background-repeat: repeat-x;
  color: #fff;
  background-image: linear-gradient(to bottom,#337ab7 0,#438ac7 100%)
}
.chosn {
  margin-top: 0;
  margin-bottom: 23px;
  margin-left: 20px;
  margin-right: 20px
}
.insm {
  background: 0 0;
  padding: 0;
  list-style: none;
  margin: 0;
  margin-top: 10px;
  display: block;
  border-radius: 0
}
.btn-uplod {
  margin-top: 22px;
  float: right;
  margin-right: 20px
}
#dvCompanyFullName {
  color: #457cb4;
  font-size: 25px;
  font-weight: 500
}
.picmcaker {
  color: #74777b
}
.picmcaker i {
  font-size: 20px;
  padding-right: 4px;
  vertical-align: bottom
}
.btn-date {
  background: #fff none repeat scroll 0 0;
  border-left: medium none;
  box-shadow: 0 0 0 transparent!important;
  color: #9599a1
}
.btn-date:focus,
.btn-date:hover {
  background: 0 0;
  border-left: none;
  box-shadow: none;
  color: #457cb4
}
.sdax {
  margin-top: 7px;
  display: block;
  color: #9599a1
}
.font-14 {
  font-size: 14px!important;
  font-family: inherit;
  font-weight: 500!important;
  line-height: 1.1;
  color: inherit
}
.select-style {
  background: #fff url(/images/arrowdown.gif) no-repeat scroll 90% 50%;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 210px;
  margin-left: 18px
}
.select-style select {
  -moz-appearance: none;
  background-color: transparent;
  background-image: none;
  border: medium none;
  box-shadow: none;
  padding: 5px 8px;
  width: 130%
}
.select-style select:focus {
  outline: medium none
}
.inputText {
  color: #000
}
.dialog-mask {
  position: absolute;
  background: #fff;
  opacity: .5;
  z-index: 4
}
.dialog-layout {
  -moz-transform: scale(0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all .5s cubic-bezier(.39,1.42,.48,1) 250ms;
  -moz-transition: all .5s cubic-bezier(.39,1.42,.48,1) 250ms;
  -ms-transition: all .5s cubic-bezier(.39,1.42,.48,1) 250ms;
  -o-transition: all .5s cubic-bezier(.39,1.42,.48,1) 250ms;
  transition: all .5s cubic-bezier(.39,1.42,.48,1) 250ms;
  position: absolute;
  border: 1px solid #d7d7d7;
  background: #fff;
  box-shadow: 1px 2px 4px rgba(100,100,100,.1);
  display: none;
  z-index: 9999;
  border-radius: 3px;
  overflow: hidden
}
.dialog-layout-animate {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1)
}
.dialog-layout-close {
  -webkit-transition: all .9s cubic-bezier(.37,-.99,.24,.24) .1s;
  -moz-transition: all .9s cubic-bezier(.37,-.99,.24,.24) .1s;
  -ms-transition: all .9s cubic-bezier(.37,-.99,.24,.24) .1s;
  -o-transition: all .9s cubic-bezier(.37,-.99,.24,.24) .1s;
  transition: all .9s cubic-bezier(.37,-.99,.24,.24) .1s;
  top: -300px!important
}
.dialog-content {
  position: relative;
  color: #555;
  overflow: hidden
}
.dialog-title {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  width: 100%;
  overflow: hidden;
  font: normal 16px Microsoft Yahei;
  color: #fff;
  background-image: linear-gradient(to bottom,#f5f5f5 0,#e8e8e8 100%);
  border-bottom: 1px solid #dedede
}
.dialog-title span {
  color: #333;
  display: block;
  float: left;
  font-size: 12px;
  padding: 10px 20px
}
.dialog-close {
  position: absolute;
  right: 0;
  top: 0;
  background: 0 0;
  display: block;
  text-decoration: none;
  font-family: Tahoma;
  font-size: 20px;
  line-height: 37px;
  height: 40px;
  width: 40px;
  text-align: center;
  color: #2e5589
}
.dialog-close:hover {
  opacity: .8
}
.dialog-html {
  padding: 50px 20px 20px 20px;
  font: normal 16px Microsoft Yahei;
  line-height: 150%
}
.mute {
  color: #b0b0b0
}
.text-right {
  text-align: right
}
.text-left {
  text-align: left
}
.modal-dialog {
  padding: 0!important;
  position: relative!important
}
.bar-cell {
  line-height: 20px;
  height: 20px
}
.bar-progress {
  width: calc(38%);
  display: inline-block;
  height: 20px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative
}
.bar-cell-2line {
  line-height: 15px
}
.bar-cell-2line .bar-progress {
  margin-top: 5px
}
.bar-progress--full {
  width: 100%
}
.bar-progress-green,
.bar-progress-mid,
.bar-progress-red {
  display: inline-block;
  height: 20px;
  text-align: center;
  color: #000!important;
  line-height: 20px;
  float: left;
  -webkit-print-color-adjust: exact
}
.bar-text {
  position: absolute
}
.bar-text-top {
  margin-top: -17px
}
.bar-text-bottom span {
  position: relative;
  top: 23px
}
.bar-cell-3 {
  padding: 15px 0 35px 0
}
.bar-square {
  width: 10px;
  height: 10px;
  background: #00f;
  position: absolute;
  left: calc(45%);
  top: 15px;
  border: 1px solid #000;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}
.table.align-middle td {
  vertical-align: middle
}
.sortable-column i {
  top: 3px;
  width: 8px;
  height: 8px;
  line-height: 8px;
  position: relative
}
.sortable-column i.fa-sort-desc {
  top: -1px
}
.panel-widget {
  position: relative;
  overflow-y: auto
}
.panel-widget.height-530 {
  height: 530px
}
.panel-widget.height-600 {
  height: 600px
}
.panel-widget.height-300 {
  height: 300px
}
.alerts-widget,
.auto-height-widget {
  height: auto!important
}
.panel-collapsed {
  min-height: 0;
  height: auto
}
.margin-top-5 {
  margin-top: 5px
}
.footer-table-no-border {
  border: none!important
}
.footer-table-no-border thead {
  border: 1px solid #ddd
}
.footer-table-no-border tfoot td {
  border: none!important;
  background: #fff!important
}
.menu-separator {
  height: 10px;
  width: calc(150%);
  background: #f5f5f5;
  margin: 10px -25px
}
.operation-select {
  margin-top: 3px
}
.pie-chart-margin {
  text-align: center;
  margin: 0 auto
}
.pie-view-more {
  margin-top: -15px;
  display: block;
  outline: 0!important
}
.operation-wrapper {
  position: relative
}
.operation-wrapper select {
  position: absolute;
  top: 0;
  z-index: 2
}
.data-list-widget {
  margin-top: 40px
}
.data-list-widget .pie-view-more {
  margin-top: 0
}
.sortable-placeholder {
  width: 100%;
  height: 200px;
  background-color: #fff;
  border: 1px dashed #ddd;
  margin-bottom: 20px
}
.dirtySettingsField {
  background-color: #faf0a5
}
.cable-settings-btn {
  background-image: linear-gradient(to bottom,#5bc0de 0,#2aabd2 100%);
  background-repeat: repeat-x;
  border-color: #28a4c9;
}
.stackHTML {
  color: #fff;
  text-align: center;
}
.stackHTML strong {
  color: deepskyblue;
}
::-ms-clear { display: none; }
.stackHTML-table, .stackHTML-table tr th, .stackHTML-table tr td {
  text-align: center;
  color: white;
}
.stackHTML-table {
  width: 230px;
}
.ui-tooltip.device-changes-log {
  min-width: fit-content;
  text-align: center;
}
.tcenter {
  text-align: center;
}
.height-auto > .panel {
  height: auto
}
.p-l-5 {
  padding-left: 5px
}
.alerts-widget h4 {
  position: relative;
  z-index: 1;
  left: 50%;
  margin-left: 14px
}
.alerts-widget .chart-wrapper {
  position: relative
}
.alert-summary-empty {
  height: 110px;
  text-align: center;
  padding-top: 50px
}
.battery-performance-table td:nth-child(2) {
  padding-right: 0;
  padding-left: 0
}
.battery-performance-table td:nth-child(3) {
  padding-left: 0
}
.cards {
  height: 132px;
  text-align: center;
  line-height: 132px;
  cursor: pointer;
  font-weight: 700;
  width: 164px;
  background-repeat: no-repeat
}
.back-btn-card,
.sub-cards {
  color: #fff;
  background-color: #f59d45;
  height: 60px;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.3em
}
.back-btn-card:hover,
.sub-cards:hover {
  border: 2px solid #c2dfed
}
.back-btn,
.save-btn {
  color: #fff;
  background-color: #f59d45;
  height: 50px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.2em;
  width: 100%;
  display: inline-block
}
.back-btn.gray,
.save-btn.gray {
  background-color: #bcbcbc
}
.back-btn:hover,
.lcd-btn:hover,
.save-btn:hover {
  border: 2px solid #c2dfed
}
.back-btn.gray:hover,
.save-btn.gray:hover {
  border: none;
  cursor: default
}
.back-btn,
.back-btn-card {
  color: #000;
  background-color: #bcbcbc
}
.invalid-input {
  background-color: #fdd
}


.ch-Quantum3-charging {
  background: url(/images/Quantum3_Charging.gif) no-repeat;
  background-size: 82px auto
}
.ch-Quantum3-charging-info {
  background: url(/images/Quantum3_i.gif) no-repeat;
  background-size: 82px auto
}
.ch-Quantum3-charging-alert {
  background: url(/images/fc_Quantum3.gif) no-repeat;
  background-size: 82px auto
}
.ch-Quantum3-idle {
  background: url(/images/Quantum3_Idle_Connected.png) no-repeat;
  background-size: 82px auto
}
.ch-Quantum3-idle-info {
  background: url(/images/Quantum3_Idle_Connected_info.png) no-repeat;
  background-size: 82px auto
}
.ch-Quantum3-idle-alert {
  background: url(/images/Quantum3_Idle_Connected_Alert.png) no-repeat;
  background-size: 82px auto
}
.ch-Quantum3-disconnected {
  background: url("/images/Quantum3_Not Connected.png") no-repeat;
  background-size: 82px auto
}
.ch-Quantum3-disconnected-day {
  background: url("/images/Quantum3_Not Connected__1 Day.png") no-repeat;
  background-size: 82px auto
}
.ch-Quantum3-disconnected-week {
  background: url("/images/Quantum3_Not Connected__1 Week.png") no-repeat;
  background-size: 82px auto
}
.ch-Quantum3-disconnected-disabled {
  background: url(/images/Quantum3_disabled.png) no-repeat;
  background-size: 82px auto
}


.ch-Q4-charging {
  background: url(/images/4x_Charging.gif) no-repeat;
  background-size: 82px auto
}
.ch-Q4-charging-info {
  background: url(/images/4x_i.gif) no-repeat;
  background-size: 82px auto
}
.ch-Q4-charging-alert {
  background: url(/images/fc_4x.gif) no-repeat;
  background-size: 82px auto
}
.ch-Q4-idle {
  background: url(/images/4x_Idle_Connected.png) no-repeat;
  background-size: 82px auto
}
.ch-Q4-idle-info {
  background: url(/images/4x_Idle_Connected_info.png) no-repeat;
  background-size: 82px auto
}
.ch-Q4-idle-alert {
  background: url(/images/4x_Idle_Connected_Alert.png) no-repeat;
  background-size: 82px auto
}
.ch-Q4-disconnected {
  background: url("/images/4x_Not Connected.png") no-repeat;
  background-size: 82px auto
}
.ch-Q4-disconnected-day {
  background: url("/images/4x_Not Connected__1 Day.png") no-repeat;
  background-size: 82px auto
}
.ch-Q4-disconnected-week {
  background: url("/images/4x_Not Connected__1 Week.png") no-repeat;
  background-size: 82px auto
}
.ch-Q4-disconnected-disabled {
  background: url(/images/4x_disabled.png) no-repeat;
  background-size: 82px auto
}
.ch-Q6-charging {
  background: url(/images/6x_Charging.gif) no-repeat;
  background-size: 110px auto
}
.ch-Q6-charging-info {
  background: url(/images/6x_i.gif) no-repeat;
  background-size: 110px auto
}
.ch-Q6-charging-alert {
  background: url(/images/fc_6x.gif) no-repeat;
  background-size: 110px auto
}
.ch-Q6-idle {
  background: url(/images/6x_Idle_Connected.png) no-repeat;
  background-size: 110px auto
}
.ch-Q6-idle-info {
  background: url(/images/6x_Idle_Connected_info.png) no-repeat;
  background-size: 110px auto
}
.ch-Q6-idle-alert {
  background: url(/images/6x_Idle_Connected_Alert.png) no-repeat;
  background-size: 110px auto
}
.ch-Q6-disconnected {
  background: url("/images/6x_Not Connected.png") no-repeat;
  background-size: 110px auto
}
.ch-Q6-disconnected-day {
  background: url("/images/6x_Idle_Not Connected__1 Day.png") no-repeat;
  background-size: 110px auto
}
.ch-Q6-disconnected-week {
  background: url("/images/6x_Idle_Not Connected__1 Week.png") no-repeat;
  background-size: 110px auto
}
.ch-Q6-disconnected-disabled {
  background: url(/images/6x_disabled.png) no-repeat;
  background-size: 110px auto
}
.ch-Q12-charging {
  background: url(/images/12x_Charging.gif) no-repeat;
  background-size: 110px auto
}
.ch-Q12-charging-info {
  background: url(/images/12x_i.gif) no-repeat;
  background-size: 110px auto
}
.ch-Q12-charging-alert {
  background: url(/images/fc_12x.gif) no-repeat;
  background-size: 110px auto
}
.ch-Q12-idle {
  background: url(/images/12x_Idle_Connected.png) no-repeat;
  background-size: 110px auto
}
.ch-Q12-idle-info {
  background: url(/images/12x_Idle_connected_info.png) no-repeat;
  background-size: 110px auto
}
.ch-Q12-idle-alert {
  background: url(/images/12x_Idle_Connected_Alert.png) no-repeat;
  background-size: 110px auto
}
.ch-Q12-disconnected {
  background: url("/images/12x_Not Connected.png") no-repeat;
  background-size: 110px auto
}
.ch-Q12-disconnected-day {
  background: url("/images/12x_Idle_Not Connected__1 Day.png") no-repeat;
  background-size: 110px auto
}
.ch-Q12-disconnected-week {
  background: url("/images/12x_Idle_Not Connected__1 Week.png") no-repeat;
  background-size: 110px auto
}
.ch-Q12-disconnected-disabled {
  background: url(/images/12x_disabled.png) no-repeat;
  background-size: 110px auto
}
.ch-gse-Q6-charging {
	background: url("/images/GSE/GSE6x_Charging.gif") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q6-charging-alert {
	background: url("/images/GSE/GSE6x_Charging_Alert.gif") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q6-idle {
	background: url("/images/GSE/GSE6x_Idle_Connected.png") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q6-idle-alert {
	background: url("/images/GSE/GSE6x_Idle_Connected_Alert.png") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q6-disconnected {
	background: url("/images/GSE/GSE6x_Not_Connected.png") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q6-disconnected-Alert {
	background: url("/images/GSE/GSE6x_Idle_Connected_Alert.png") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q6-disconnected-day {
	background: url("/images/GSE/GSE6x_Not_Connected_1Day.png") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q6-disconnected-week {
	background: url("/images/GSE/GSE6x_Not_Connected_1Week.png") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q6-disconnected-disabled {
	background: url("/images/GSE/GSE6x_disabled.png") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q12-charging {
	background: url("/images/GSE/GSE12x_Charging.gif") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q12-charging-alert {
	background: url("/images/GSE/GSE12x_Charging_Alert.gif") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q12-idle {
	background: url("/images/GSE/GSE12x_Idle_Connected.png") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q12-idle-alert {
	background: url("/images/GSE/GSE12x_Idle_Connected_Alert.png") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q12-disconnected {
	background: url("/images/GSE/GSE12x_Not_Connected.png") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q12-disconnected-day {
	background: url("/images/GSE/GSE12x_Not_Connected_1Day.png") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q12-disconnected-week {
	background: url("/images/GSE/GSE12x_Not_Connected_1Week.png") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q12-disconnected-disabled {
	background: url("/images/GSE/GSE12x_disabled.png") no-repeat;
	background-size: 110px auto;
}
.ch-gse-Q12-idle-info {
  background: url("/images/GSE/GSE12x_Idle_connected.jpg") no-repeat;
  background-size: 110px auto;
}
.ch-gse-Q12-charging-info {
  background: url("/images/GSE/GSE12x_Charging_info.gif") no-repeat;
  background-size: 110px auto;
}
.ch-Q4-thumbnail,
.ch-Quantum3-thumbnail  {
  width: 82px
}
.ch-Q12-thumbnail,
.ch-Q6-thumbnail {
  margin-right: 10px;
  width: 110px
}
.ch-Q4-thumbnail,
.ch-Quantum3-thumbnail,
.ch-Q6-thumbnail {
  height: 100px
}
.ch-Q12-thumbnail {
  height: 146px
}
.sm-font {
  font-size: 10px
}
.lg-font {
  font-size: 16px
}
.onoffswitch {
  position: relative;
  width: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none
}
.onoffswitch-checkbox {
  display: none
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 10px;
  padding: 0;
  line-height: 10px;
  border: 0 solid #fff;
  border-radius: 15px;
  background-color: #9e9e9e
}
.onoffswitch-label:before {
  content: "";
  display: block;
  width: 15px;
  margin: -2.5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  border-radius: 15px;
  box-shadow: 0 6px 12px 0 #757575
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
  background-color: #42a5f5
}
.onoffswitch-checkbox:checked + .onoffswitch-label,
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  border-color: #42a5f5
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  right: 0;
  background-color: #2196f3;
  box-shadow: 3px 6px 18px 0 rgba(0,0,0,.2)
}
input.onoffswitch-checkbox {
  display: none
}
.bg-grey {
  background-color: #ababab
}
.bg-black {
  background-color: #000
}
.bg-blue {
  background-color: #1167cd
}
.LCD-body div,
.p-5 {
  padding: 5px
}
.m-5 {
  margin: 5px
}
.m-r-20 {
  margin-right: 20px
}
.white-text {
  color: #fff
}
.fixed-pos {
  position: fixed
}
.text-underline {
  text-decoration: underline
}
.body-bg-color {
  background-color: #e6e9ed
}
tags-input .tags {
  box-shadow: none;
  border: 0
}
.bv-connected {
  background: url(/images/battview_connected.png) no-repeat
}
.bv-not-connected {
  background: url(/images/battview_not_connected.png) no-repeat
}
.bv-mobile-not-connected-week {
  background: url(/images/bv_mobile_not_connected.png) no-repeat
}
.bv-not-connected-week {
  background: url("/images/BattView_Not Connected__1 Week.png") no-repeat
}
.bv-not-connected-day {
  background: url("/images/BattView_Not Connected__1 Day.png") no-repeat
}

.tk-connected {
  background: url(/images/truckviews/Forklift_Connected.png) no-repeat
}
.tk-not-connected {
  background: url(/images/truckviews/Forklift_Not_Connected.png) no-repeat
}
.tk-not-connected-day {
  background: url(/images/truckviews/Forklift_Not_Connected_1D.png) no-repeat
}
.tk-not-connected-week {
  background: url(/images/truckviews/Forklift_Not_Connected_7D.png) no-repeat
}
.tk-not-connected-no-act {
  background: url(/images/truckviews/Forklift_Not_Connected_No_ACTintelligent.png) no-repeat
}

.bv-thumbnail {
  height: 45px;
  margin-right: 10px;
  width: 150px
}

.tk-thumbnail {
	height: 95px;
	width: 150px;
}
.p-b-5 {
  padding-bottom: 5px
}
.p-b-30 {
  padding-bottom: 30px
}
.listing-tags {
  display: block;
  margin-bottom: 3px
}
.tooltip-inner {
  white-space: pre;
  max-width: none
}
.margin-bottom {
  margin-bottom: 17px
}
.margin-left {
  margin-left: 17px
}
.margin-top {
  margin-top: 17px
}
.margin-right {
  margin-right: 17px
}
.margin-bottom-xlg {
  margin-bottom: 34px
}
.margin-left-xlg {
  margin-left: 34px
}
.margin-top-xlg {
  margin-top: 34px
}
.margin-top-xxlg {
  margin-top: 70px
}
.margin-right-xlg {
  margin-right: 34px
}
.margin-bottom-3xlg {
  margin-bottom: 100px
}
.margin-bottom-lg {
  margin-bottom: 25.5px
}
.margin-left-lg {
  margin-left: 25.5px
}
.margin-top-lg {
  margin-top: 25.5px
}
.margin-right-lg {
  margin-right: 25.5px
}
.margin-bottom-sm {
  margin-bottom: 8.5px
}
.margin-left-sm {
  margin-left: 8.5px
}
.margin-top-sm {
  margin-top: 8.5px
}
.margin-right-sm {
  margin-right: 8.5px
}
.margin-bottom-xs {
  margin-bottom: 4.25px
}
.margin-left-xs {
  margin-left: 4.25px
}
.margin-top-xs {
  margin-top: 4.25px
}
.margin-right-xs {
  margin-right: 4.25px
}
.padding-50 {
  padding: 50px
}
.line-height-70 {
  line-height: 70px
}
.line-height-200 {
  line-height: 200px
}
@media (max-width:64em) {
  .alert-settings {
	padding: 0
  }
}
@media (max-width:64em) {
  .alert-settings form {
	margin: 0
  }
}
.alert-settings form .container-fluid .boxs3 {
  padding: 50px
}
@media (max-width:64em) {
  .alert-settings form .container-fluid .boxs3 {
	margin: 5px;
	padding: 10px
  }
  .alert-settings form .container-fluid .boxs3 fieldset .form-group label {
	line-height: 22px;
	padding-bottom: 5px;
	padding-top: 5px
  }
}
.alert-settings form .col-md-7 {
  padding-right: 50px
}
.is-disabled {
  pointer-events: none
}
.invisible {
  visibility: hidden
}
#update-schedule {
  z-index: 999!important
}
.inline-form-control {
  width: auto;
  display: inline-block
}
.red {
  background-color: red
}
.sidebar-nav li a.active.intelligence,
.sidebar-nav li a.active.router,
.sidebar-nav li a.intelligence:active,
.sidebar-nav li a.intelligence:hover,
.sidebar-nav li a.router:active,
.sidebar-nav li a.router:hover {
  background: #3364a8 none repeat scroll 0 0;
  border-left: 3px solid #98b6e0;
  color: #fff;
  text-decoration: none
}
div#footer-wrapper {
  background: #fff;
  padding: 10px;
  z-index: 999;
  overflow: auto;
  font-size: 12px;
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 40px
}
.beta {
  background: #74777b;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  top: -13px;
  position: relative;
  padding: 0 2px;
  width: 30px;
  height: 13px
}
.map {
  height: 467px;
  display: block
}
.customer-map {
  height: 432px;
  display: block
}
.no-gutter-left {
  padding-left: 0
}
.no-gutter-right {
  padding-right: 0
}
.no-gutter {
  padding-right: 0;
  padding-left: 0
}
@media all and (max-width:64em) {
  .no-gutter-left-phone {
	padding-left: 0;
	padding-right: 0
  }
}
.map-container {
  background-color: #00f;
  height: 75%;
  min-height: 50%;
  min-width: 46%;
  position: fixed;
  right: 1.1%
}
.navbar-item {
  color: #457cb4!important;
  text-align: left!important
}
@media (max-width:1200px) {
  .navbar-header {
	float: none
  }
  .navbar-left,
  .navbar-right {
	float: none!important
  }
  .navbar-toggle {
	display: block
  }
  .navbar-collapse {
	border-top: 1px solid transparent;
	box-shadow: inset 0 1px 0 rgba(255,255,255,.1)
  }
  .navbar-fixed-top {
	top: 0;
	border-width: 0 0 1px
  }
  .navbar-collapse.collapse {
	display: none!important
  }
  .navbar-nav {
	float: none!important;
	margin-top: 7.5px
  }
  .navbar-nav > li {
	float: none
  }
  .navbar-nav > li > a {
	padding-top: 10px;
	padding-bottom: 10px
  }
  .collapse.in {
	display: block!important
  }
  .navbar-nav .open .dropdown-menu {
	position: static;
	float: none;
	width: auto;
	margin-top: 0;
	background-color: transparent;
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none
  }
  .dropdown-submenu ul li {
	margin-left: 20px
  }
  .navbar-brand {
	transform: translateX(-50%);
	left: 50%;
	position: absolute;
	border-right: none
  }
  .navbar-header {
	height: 71px
  }
  #page-content-wrapper {
	padding: 80px 0 15px 0
  }
}
.sitemap .angular-google-map,
.sitemap .angular-google-map-container {
  width: 100%;
  height: 100%
}
.sitemap #site-wrapper {
  position: relative;
  transition: all .5s ease
}
@media (min-width:768px) {
  .sitemap #site-wrapper {
	padding-left: 250px
  }
}
.sitemap #page-content-wrapper {
  padding-top: 30px
}
.sitemap #over_map {
  position: absolute;
  right: 2%;
  z-index: 99;
  background: #fff;
  border: 4px solid #000;
  border-radius: 10px;
  padding: 10px;
  width: 250px;
  min-height: 250px
}
.sitemap .customer-site-box {
  top: 10px
}
.sitemap .tags-container {
  padding: 10px
}
.sitemap .tag {
  font-size: 13px;
  padding: .3em .4em .4em;
  margin: .1em .1em;
  float: left
}
.sitemap .tag a {
  color: #bbb;
  cursor: pointer;
  opacity: .6
}
.sitemap .tag a:hover {
  opacity: 1
}
.sitemap .tag .remove {
  vertical-align: bottom;
  top: 0
}
.sitemap .tag a {
  margin: 0 0 0 .3em
}
.sitemap .tag a .glyphicon-white {
  color: #fff;
  margin-bottom: 2px
}
.sitemap .label-info {
  background-color: #2e5589
}
.sitemap .icon-close {
  text-decoration: none;
  float: right;
  margin: -5px;
  color: #000
}
.sitemap .site-info {
  margin-top: 15px
}
.sitemap .site-info .link {
  text-decoration: none;
  color: #337ab7;
  font-weight: 600;
  font-size: 22px
}
.sitemap .site-info .address {
  color: #337ab7;
  font-weight: 400;
  font-size: 12px;
  margin-top: 5px
}
.sitemap .site-info .charger {
  font-weight: 500;
  color: #000;
  margin-top: 20px;
  font-size: 13px
}
.sitemap .site-info .charger span {
  font-weight: 900
}
.sitemap .site-info .battery {
  font-weight: 500;
  color: #000;
  margin-top: 10px;
  font-size: 13px
}
.sitemap .site-info .battery span {
  font-weight: 900
}
.sitemap .site-info .intelligent {
  font-weight: 500;
  color: #000;
  margin-top: 10px;
  font-size: 13px
}
.white-tooltip + .tooltip.top > .tooltip-inner {
  background-color: #fff;
  color: #000
}
.white-tooltip + .tooltip.top > .tooltip-arrow {
  border-top-color: #fff
}
#site-info-images-grid {
  padding: 0
}
#site-info-images-grid * {
  margin: 0;
  padding: 0;
  font-family: Lato,sans-serif
}
#site-info-images-grid body {
  /*background: url(images/noise_light-grey.jpg);*/
  font-family: 'Helvetica Neue',arial,sans-serif;
  font-weight: 200
}
#site-info-images-grid h1 {
  font-size: 4em;
  font-weight: 400;
  margin: 0 0 20px;
  text-align: center;
  text-shadow: 1px 1px 0 #fff,2px 2px 0 #bbb
}
#site-info-images-grid hr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #fff;
  margin: 25px 0;
  clear: both
}
#site-info-images-grid .desc {
  font-family: Lato,sans-serif;
  color: #337ab7!important;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px
}
#site-info-images-grid .centered {
  text-align: center
}
#site-info-images-grid .wrapper {
  width: 100%;
  padding: 3px
}
#site-info-images-grid .container {
  width: 100%;
  margin: 0 auto
}
#site-info-images-grid ul.grid-nav {
  list-style: none;
  font-size: .85em;
  font-weight: 200;
  text-align: center
}
#site-info-images-grid ul.grid-nav li {
  display: inline-block
}
#site-info-images-grid ul.grid-nav li a {
  display: inline-block;
  background: #999;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px
}
#site-info-images-grid ul.grid-nav li a:hover {
  background: #7b0
}
#site-info-images-grid ul.grid-nav li a.active {
  background: #333
}
#site-info-images-grid .grid-container {
  display: block
}
#site-info-images-grid ul.rig {
  list-style: none;
  font-size: 0;
  margin-left: -2.5%
}
#site-info-images-grid ul.rig li {
  display: inline-block;
  padding: 10px;
  margin: 0;
  background: #fff;
  border: 1px solid #ddd;
  font-size: 16px;
  font-size: 1rem;
  vertical-align: top;
  box-shadow: 0 0 5px #ddd;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box
}
#site-info-images-grid ul.rig li img {
  margin: 0;
  max-height: 62px;
  max-width: 115px
}
#site-info-images-grid ul.rig li h3 {
  margin: 0 0 5px;
  font-weight: 700;
  font-size: 28px
}
#site-info-images-grid ul.rig li h4 {
  margin: 0 0 5px
}
#site-info-images-grid ul.rig li p {
  font-size: 1.2em;
  line-height: 1.5em;
  color: #fff!important;
  font-family: Lato,sans-serif;
}
#site-info-images-grid ul.rig.columns-2 li {
  width: 50%
}
#site-info-images-grid ul.rig.columns-3 li {
  width: 30.83%
}
#site-info-images-grid ul.rig.columns-4 li {
  width: 22.5%
}
#site-info-images-grid li.iconic {
  color: #fff!important
}
#site-info-images-grid li.iconic h3 {
  position: absolute;
  top: 19%;
  left: 34%;
  color: #fff!important
}
#site-info-images-grid li.iconic h4 {
  position: absolute;
  top: 25%;
  left: 30%;
  font-size: 25px;
  font-weight: 700;
  color: #fff!important
}
@media (max-width:64em) {
  #site-info-images-grid li.iconic h4 {
	left: 35%
  }
}
@media (max-width:64em) {
  #site-info-images-grid li.iconic p {
	left: 35%
  }
}
#site-info-images-grid .fixed-height ul li {
  height: 110px
}
#site-info-images-grid .grid-container ul li {
  overflow: hidden;
  box-shadow: 0 0 5px #232434!important;
  position: relative;
  color: #f8fafe!important;
  background: -moz-radial-gradient(center,ellipse cover,#111328 0,#2b2b44 99%,#2b2b44 100%)!important;
  background: -webkit-gradient(radial,center center,0,center center,100%,color-stop(0,#111328),color-stop(99%,#2b2b44),color-stop(100%,#2b2b44))!important;
  background: -webkit-radial-gradient(center,ellipse cover,#111328 0,#2b2b44 99%,#2b2b44 100%)!important;
  background: -o-radial-gradient(center,ellipse cover,#111328 0,#2b2b44 99%,#2b2b44 100%)!important;
  background: -ms-radial-gradient(center,ellipse cover,#111328 0,#2b2b44 99%,#2b2b44 100%)!important;
  background: radial-gradient(ellipse at center,#111328 0,#2b2b44 99%,#2b2b44 100%)!important
}
#site-info-images-grid li.left {
  margin-left: -1.5%!important;
  margin-right: 1.5%!important;
  margin-top: 1.5%!important
}
@media (max-width:64em) {
  #site-info-images-grid li.left {
	margin-left: auto!important;
	margin-right: auto!important
  }
}
#site-info-images-grid li.right {
  margin-top: 1.5%!important
}
#site-info-images-grid li.iconic p {
  top: 60%;
  left: 34%;
  position: absolute;
  padding-right: 2px
}
#site-info-images-grid li.iconic p.info-desc {
  top: 60%;
  left: 30%;
  position: absolute;
  padding-right: 2px
}
@media (max-width:64em) {
  #site-info-images-grid li.iconic p.info-desc {
	left: 35%
  }
}
@media (max-width:1199px) {
  #site-info-images-grid .container {
	width: auto;
	padding: 0 10px
  }
}
@media (max-width:480px) {
  #site-info-images-grid ul.grid-nav li {
	display: block;
	margin: 0 0 5px
  }
  #site-info-images-grid ul.grid-nav li a {
	display: block
  }
  #site-info-images-grid ul.rig {
	margin-left: 0
  }
  #site-info-images-grid ul.rig li {
	width: 100%!important;
	margin: 0 0 5px;
	padding-bottom: 20px
  }
}
@media (min-width:1200px) {
  #site-info-static-data {
	margin-right: 40px
  }
}
#site-info-static-data img {
  vertical-align: middle;
  display: inline;
  width: 30px;
  height: auto;
  float: left;
  margin: 0 10px 0 0
}
#site-info-static-data div {
  overflow: hidden
}
#site-info-static-data h4 {
  display: block;
  vertical-align: middle;
  margin: 0;
  padding: 0
}
#site-info-static-data ul {
  padding-left: 0
}
#site-info-static-data li {
  list-style-type: none;
  margin-bottom: 30px
}
#site-info-static-data p {
  display: block;
  vertical-align: middle;
  margin: 0;
  padding: 0
}
.expand-btn {
  position: absolute;
  right: 5px;
  bottom: 5px
}
.card-sections {
  display: flex;
  flex-flow: row wrap
}
.card-sections > div {
  flex: 1;
  padding: .5rem
}
.card-option {
  display: none!important
}
.card-option:not(:disabled) ~ .card-label {
  cursor: pointer
}
.card-option:disabled ~ .card-label {
  color: #bcc2bf;
  border-color: #bcc2bf;
  box-shadow: none;
  cursor: not-allowed
}
.card-label {
  height: 100%;
  display: block;
  background: #fff;
  border: 2px solid #3ab63a;
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  box-shadow: 0 3px 10px -2px rgba(161,170,166,.5);
  position: relative
}
.card-option:checked + .card-label {
  background: #3ab63a;
  color: #fff;
  box-shadow: 0 0 20px rgba(58,182,58,.75)
}
.card-option:checked + .card-label::after {
  color: #3d3f43;
  font-family: FontAwesome;
  border: 2px solid #3ab63a;
  content: "\f00c";
  font-size: 24px;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 2px 5px -2px rgba(0,0,0,.25)
}
.card-option.red:checked + .card-label {
  background: #fdd;
  border-color: #fdd;
  color: #000
}
.card-option.grey:checked + .card-label {
  background: #b0b0b0;
  border-color: #b0b0b0
}
.card-option.yellow:checked + .card-label {
  background: #faf0a5;
  border-color: #faf0a5;
  color: #000
}
.overflow-y {
  overflow-y: auto
}
.no-gutter > [class*=col-] {
  padding-right: 0;
  padding-left: 0
}
.default-cursor {
  cursor: default!important
}
.ui-select-multiple input.ui-select-search {
  width: 100%!important
}
.add-note-action li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap
}
.word-break-btn {
  white-space: normal;
  word-break: break-all
}
.x-small {
  font-size: x-small
}
.beta-site-analytics {
  background: #74777b;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  top: 4px;
  position: relative;
  width: 31px;
  height: 15px;
  padding: 5px
}
.beta-site-analytics span {
  position: absolute;
  top: -3px;
  left: 5px
}
.inline-block {
  display: inline-block!important
}
legend p {
  font-size: 15px
}
.padding-left-30 {
  padding-left: 30px!important
}
.animate-if.ng-enter {
  -webkit-transition: 1s linear all;
  -moz-transition: 1s linear all;
  -ms-transition: 1s linear all;
  -o-transition: 1s linear all;
  transition: 1s linear all
}
.animate-if.ng-enter {
  max-height: 0;
  opacity: 0
}
.animate-if.ng-enter.ng-enter-active {
  max-height: 999px;
  opacity: 1
}
#service-page .tags {
  background: #d9edf7
}
#service-page notes div table {
  background: #d9edf7!important
}
.ng-cloak,
[ng-cloak],
[ng\:cloak] {
  display: none!important
}
.customers-report .nav-tabs > li.active > a,
.customers-report .nav-tabs > li.active > a:focus,
.customers-report .nav-tabs > li.active > a:hover {
  background: #f5f5f5
}
.drag-handle {
  cursor: -webkit-grabbing;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  opacity: 1;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.dropdown-menu.options-menu {
  min-width: 115px;
}
.dropdown-menu.options-menu li{
  cursor: pointer;
}
.blue-border-b {
  border-bottom: 3px solid #457cb4;
}
.font-size-22 {
  font-size: 22px;
}
.orange-bg {
  background-color: #fbd99a;
}
.red-bg {
  background-color: #fb9a9a;
}
.bold-900 {
  font-weight: 900;
}
#sidebar-wrapper > ul > div > label {
	color: orange;
    padding-left: 10px;
    font-weight: normal;
    font-size: 1.2em;
}
.replacement-tag {
  border: solid 2px green;
}
#sidebar-wrapper > ul > div > .form-control {
	color: blue;
	margin-bottom: 5px;
}
.refresh-browser-banner {
  border-radius: 0;
  margin-left: -25px;
  margin-right: -25px;
  padding: 23px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #ffd966;
}

.ag-filter-wrapper {
 margin: 0;
}

.ag-header-cell:not(.ag-header-cell-auto-height) .ag-header-cell-comp-wrapper:after {
  border-right: 1px solid rgba(189,195,199,.5);
  content: " ";
  height: 16px;
  margin-top: 8px;
  position: absolute;
  text-indent: -2000px;
  top: 0;
  right: 0;
}

.ag-ltr .ag-header {
  height: 32px;
}

.ag-header-cell-text {
  font-size: 11px;
}
