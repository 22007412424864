@media print {
	/*html, body {
        height: 99%;    
    }*/

    body {-webkit-print-color-adjust: exact;}


    thead { display: table-row-group; }
    tfoot { display: table-row-group; }
    tr { page-break-inside: avoid; }


    @page {
        size: landscape;

    }

  	.print-modalcontent * {
        visibility: visible;
    }

    .noprint{
    	visibility: hidden;
        display: none;
    }

    .modal{
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;
        min-height:550px;
    }

    .chart_title{
        display: block;
    }

    div{
        transform-origin: 0 0;
        page-break-after: avoid;
        page-break-before: avoid;
        font-size: 11px;
    }

    .page-break-inside{
        page-break-inside: avoid;
    }
    .showOnPrint{
        display: block;
    }

    #header, .ng-hide, .no-print{
        display: none;
    }

    .chart{
        height: 200px;
    }
    .panel-widget {
        height: auto;
		page-break-inside: avoid;
    }
    .panel-heading {
        background-color: #f5f5f5 !important;
        font-size: 20px;
    }
    .reportTable>tbody>tr>td {
        padding: 4px;
    }
}